/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from "react";

import CustomSwitch from "../components/CustomSwitch";
import DetailsContainer from "../components/DetailsContainer";
import ObservedVisitRoute from "../components/ObservedVisitRoute";
import OuterWizardContainer from "../components/OuterWizardContainer";
import PackagePriceContainer from '../components/PackagePriceContainer';
import Wizard from "../components/Wizard";
import { useWizardActions,
  useWizardState,
  useWizardProps
} from '../state';
import { mq } from '../styles/breakpoints';
import { InitHeader } from "./headers";
import { getPackageAddonPrice, getPackageAddonMandatory } from '../state/packages';

const toggleContainerStyles = css`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;

  li {
    display: flex;
    align-content: center;
    align-items: center;

    padding-bottom: 2em !important;


    img {
      width: 2em;
      max-height: 2em;
    }

    p {
      font-weight: normal;
      font-size: 1.1em;
      display: inline-block;
      padding-left: 1em;
    }

    ${mq.sm} {
      padding-bottom: 1.5em !important;

      img {
        width: 1.2em;
        max-height: 1.2em;
      }

      p {
        font-size: 1em;
      }
    }

    &:last-child {
      padding-bottom: 0px !important;
    }
  }
`;

const IncludedCheckMark = () => {
  return (
    <div style={{ marginRight: "1em", width: "37px" }}>
      <svg width="20" height="20" viewBox="0 0 494 500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="246.667" cy="250" rx="246.667" ry="250" fill="#4592FF" fillOpacity="0.5"></ellipse>
        <path fillRule="evenodd" clipRule="evenodd" d="M353.36 163.338C355.712 165.218 357.67 167.543 359.123 170.18C360.576 172.817 361.494 175.715 361.826 178.707C362.158 181.7 361.897 184.728 361.057 187.62C360.217 190.511 358.816 193.208 356.933 195.558L234.774 348.256C231.054 352.904 225.671 355.92 219.765 356.667C213.859 357.414 207.895 355.832 203.135 352.257L142.056 306.447C137.196 302.802 133.983 297.376 133.124 291.363C132.265 285.349 133.83 279.24 137.475 274.381C141.119 269.521 146.546 266.308 152.559 265.449C158.573 264.59 164.681 266.155 169.541 269.8L212.877 302.294L321.11 166.942C322.987 164.588 325.31 162.626 327.946 161.171C330.582 159.715 333.48 158.794 336.472 158.459C339.465 158.124 342.494 158.383 345.386 159.22C348.279 160.057 350.978 161.457 353.329 163.338H353.36Z" fill="#4592FF"></path>
      </svg>
    </div>
  )
}

const GeekAI = (props) => {
  const { packages } = useWizardProps();
  const geekAiPrice = getPackageAddonPrice(packages, props.billingPlan, "geek_ai");
  const price = geekAiPrice === 0 ? "Included" : `$${geekAiPrice}/mo`;
  return (
    <React.Fragment>
      <h2>Geek AI: Robin</h2>
      <div className="switch-container" style={{ marginTop: 0 }}>
        <ul css={toggleContainerStyles}>
          <li>
            {geekAiPrice === 0 ? <IncludedCheckMark /> :
            <CustomSwitch
              disabled={geekAiPrice === 0 ? true : false}
              checked={props.checked}
              onChange={props.onChange}
            />}
            <p>{price} - Your personal SMS AI assistant designed to help you schedule appointments with new leads while you are out closing deals.</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const SystemSetup = (props) => {
  const { packages } = useWizardProps();
  const mandatory = getPackageAddonMandatory(packages, props.billingPlan, "system_setup");
  const price = getPackageAddonPrice(packages, props.billingPlan, "system_setup");
  return (
    <React.Fragment>
      <h2>System Setup</h2>
      <div className="switch-container" style={{ marginTop: 0, marginBottom: '1em' }}>
        <ul css={toggleContainerStyles}>
          <li>
            {mandatory ? <IncludedCheckMark /> :
            <CustomSwitch
              disabled={mandatory}
              checked={props.checked}
              onChange={props.onChange}
            />}
            <p>${price} - Our team will help you set up your website, CRM, and ad campaigns (if applicable) to get you lead-ready as soon as possible.</p>

          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const LiveTraining = (props) => {
  const { packages } = useWizardProps();
  const mandatory = getPackageAddonMandatory(packages, props.billingPlan, "live_training");
  return (
    <React.Fragment>
      <h2>Live Group Training</h2>
      <div className="switch-container" style={{ marginTop: 0, marginBottom: '1em' }}>
        <ul css={toggleContainerStyles}>
          <li>
            {mandatory ? <IncludedCheckMark /> :
            <CustomSwitch
              disabled={mandatory}
              checked={props.checked}
              onChange={props.onChange}
            />}
            <p>Included - Regular small group sessions online with our training specialists to help you get the most from Real Geeks.</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const SoloTrainingSessions = (props) => {
  const { packages } = useWizardProps();
  const mandatory = getPackageAddonMandatory(packages, props.billingPlan, "solo_training");
  const soloTrainingSessionPrice = getPackageAddonPrice(packages, props.billingPlan, "solo_training");
  const price = soloTrainingSessionPrice === 0 ? "$FREE" : `$${soloTrainingSessionPrice}`;
  return (
    <React.Fragment>
      <h2>Dedicated Training</h2>
      <div className="switch-container" style={{ marginTop: 0, marginBottom: '1em' }}>
        <ul css={toggleContainerStyles}>
          <li>
            {mandatory ? <IncludedCheckMark /> :
            <CustomSwitch
              disabled={mandatory}
              checked={props.checked}
              onChange={props.onChange}
            />}
            <p>{price} - Your dedicated training specialist will lead you and your team through our onboarding program so you can hit the ground running.</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const OptionsDetails = () => {
  const {
    setGeekAi,
    setSystemSetup,
    setLiveTraining,
    setSoloTrainingSessions,
  } = useWizardActions();
  const {
    billingPlan,
    addons: {
      geek_ai,
      system_setup,
      live_training,
      solo_training,
    },
  } = useWizardState();
  const { packages } = useWizardProps();

  return (
    <React.Fragment>
      <SystemSetup
        billingPlan={billingPlan}
        checked={system_setup}
        onChange={() => setSystemSetup(!system_setup, packages)}
      />
      <LiveTraining
        billingPlan={billingPlan}
        checked={live_training}
        onChange={() => setLiveTraining(!live_training, packages)}
      />
      <SoloTrainingSessions
        billingPlan={billingPlan}
        checked={solo_training}
        onChange={() => setSoloTrainingSessions(!solo_training, packages)}
      />
      <GeekAI
        billingPlan={billingPlan}
        checked={geek_ai}
        onChange={() => setGeekAi(!geek_ai, packages)}
      />
    </React.Fragment>
  );
};

const OptionsPicker = (_props) => {
  const {
    billingPlan,
    signupPrice,
    currentPrice,
  } = useWizardState();

  return (
    <ObservedVisitRoute>
      <InitHeader />

      <OuterWizardContainer>
        <Wizard column={false}>
          <PackagePriceContainer packageName={billingPlan} />

          <DetailsContainer style={{ height: "auto" }}>
            <OptionsDetails />
          </DetailsContainer>
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};

export default OptionsPicker;

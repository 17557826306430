import { useEffect } from 'react';
import {
  useWizardState,
  useWizardProps,
} from '../../state';

export default function useTosPaperwork(submitted) {
  const { tosUrl, shortcode, mode } = useWizardProps();
  const { userDetails: { firstName, lastName, email }, addons: { google, fb } } = useWizardState();

  useEffect(() => {
    if (submitted) {
      const timeout = setTimeout(() => {
        const body = {
          email,
          full_name: `${firstName} ${lastName}`,

        };

        body.promo = true;

        // If customer has selected google or fb marketing, we may want to show
        // a different TOS.
        body.google = google;
        body.fb = fb;
        body.mode = mode;

        if (JSON.parse(shortcode) !== '') {
          body.shortcode = JSON.parse(shortcode);
        }

        $.post(
          JSON.parse(tosUrl),
          JSON.stringify(body),
        ).done(({ paperwork }) => {
          window.location.href = paperwork;
        });
      }, 900);
      // NOTE: I set the timeout as 900 to account for how long redirect and the
      // above request will take. Overall the loading state should show for at
      // least a second.

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [submitted]);
};

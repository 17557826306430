import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { useRoute } from 'wouter';
import { useForm } from 'react-hook-form';

import {
  useWizardActions,
  useWizardState,
  useBoardOptions,
  useBoardOptionPreloader,
  useMarketOptions,
  useMarketOptionsLoader,
  useWizardProps,
} from '../../state';

import ObservedVisitRoute from '../../components/ObservedVisitRoute';
import OuterWizardContainer from '../../components/OuterWizardContainer';
import Wizard from '../../components/Wizard';
import DetailsContainer from '../../components/DetailsContainer';
import StepIndicators from '../../components/StepIndicators';

import Form, { useErrorMessages, ErrorMessageContainer } from '../../components/Form';
import InputGroup from '../../components/InputGroup';
import ClearButton from '../../components/ClearButton';
import StepButton from '../../components/StepButton';
import ContinueButton from '../../components/ContinueButton';
import DisclaimerText from '../../components/DisclaimerText';
import Loader from '../../components/Loader';

import {
  StateLabel,
  CheckBox,
  selectStyles,
} from './styled';
import useTosPaperwork from './useTosPaperwork';
import useMLSBrokerCheck from './useMLSBrokerCheck';


const CandianProvinces = [
  'ON',
  'QC',
  'NS',
  'NB',
  'MB',
  'BC',
  'PE',
  'SK',
  'AB',
  'NL',
];

const TierDescription = (props) => {
  const tier = props.targetMarket && props.targetMarket.value.tier
  const tierPackage = props.package

  if (tier) {
    let description;
    switch (tier) {
      case 1:
        description = tierPackage === "expand" ? "On average, 20-30 leads/month" : "On average, 40-60 leads/month"
        break;
      case 2:
        description = tierPackage === "expand" ? "On average, 30-40 leads/month" : "On average, 60-80 leads/month"
        break;
      case 3:
        description = tierPackage === "expand" ? "On average, 40-50 leads/month" : "On average, 80-100 leads/month"
        break;
    }

    return (
      <div style={{ marginTop: "0.5em", fontSize: "14px" }}>
        {description}. See <a href="https://www.realgeeks.com/contract-and-terms" target="_blank" class="normalLink">Terms of Service and Contract</a> for more details.
      </div>
    )
  } else {
    return null;
  }
}


function MLSBoardDetails() {
  useBoardOptionPreloader();
  const {
    mlsBoard,
    mlsBoardCountry,
    mlsBoardState,
    mlsID,
    targetMarket,
    billingPlan, } = useWizardState();
  const {
    previousStep,
    setVisitedHere,
    setBoard,
    setBoardCountry,
    setBoardState,
    setMlsId,
    setMarket,
  } = useWizardActions();

  const { handleSubmit, register, errors, watch } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: false,
    mode: 'onBlur',
    defaultValues: {
      mls_id: mlsID ? mlsID : undefined,
      mls_board_url: (mlsBoard && mlsBoard.mls_board_url) ? mlsBoard.mls_board_url : undefined,
      mls_board_name: (mlsBoard && mlsBoard.mls_board_name) ? mlsBoard.mls_board_name : undefined,
    }
  });

  useMLSBrokerCheck();

  const boards = useBoardOptions();
  const markets = useMarketOptions();

  const onCountryChange = (option) => {
    setBoardState(null);
    setBoard(null);
    setBoardCountry(option);

    setMarket(null);
  };

  const stateOptions = useMemo(() => {
    if (boards) {
      let states = Object.keys(boards).sort();
      let options = [];

      states.forEach((state) => {
        if (mlsBoardCountry.value === 'US' && (CandianProvinces.indexOf(state) < 0)) {
          options.push({value: state, label: state});
        }
        else if (mlsBoardCountry.value === 'CA' && (CandianProvinces.indexOf(state) >= 0)) {
          options.push({value: state, label: state});
        }
      });
      options.push({value: 'Other', label: 'Other'});

      return options;
    }

    else {
      return null;
    }
  }, [boards, mlsBoardCountry]);

  const [selectedState, setSelectedState] = useState(null);

  const boardOptions = useMemo(() => {
    if (boards && mlsBoardState && mlsBoardState.value !== 'Other') {
      const stateBoardValues = [...boards[mlsBoardState.value]].sort((a, b) => {
        if (a.value > b.value) {
          return 1;
        }

        if (a.value < b.value) {
          return -1;
        }

        return 0;
      });

      return [
        ...stateBoardValues,
        {
          value: 'Other',
          label: 'Other',
        },
      ];
    }

    else {
      return [{
        value: 'Other',
        label: 'Other',
      }];
    }
  }, [boards, mlsBoardState]);

  const marketOptionsLoader = useMemo(() => {
    if ((billingPlan === "expand" || billingPlan === "conquer") && (mlsBoardState)) {
      useMarketOptionsLoader(mlsBoardState.value);
    } else {
      return [];
    }
  }, [mlsBoardState]);

  const marketOptions = useMemo(() => {
    const options = markets.map((market) => {
      return {
        value: market,
        label: `${market.name} - Tier ${market.tier}`,
      };
    });
    return options;
  }, [markets]);

  const [shouldDisplayCustomBoardForm, setDisplayCustomBoardForm] = useState(mlsBoard && mlsBoard.value && mlsBoard.value === 'Other');
  const [vendorFeeToggle, setVendorFeeToggle] = useState(false);
  const [mlsStateError, setMlsStateError] = useState(false);
  const [mlsBoardError, setMlsBoardError] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const onStateChange = (option) => {
    setSelectedState(option);
    setBoard(null);
    setMarket(null);
    setVendorFeeToggle(false);

    setMlsStateError(false);
  };

  useEffect(() => {
    if (selectedState && selectedState.value === 'Other') {
      setBoardState(selectedState);
    }

    else if (selectedState) {
      setBoardState(selectedState);
      setDisplayCustomBoardForm(false);
    }
  }, [selectedState]);

  const onBoardChange = (option) => {
    if (option.value === 'Other') {
      setDisplayCustomBoardForm(true);
      setBoard({ value: 'Other', label: 'Other' });
    }

    else {
      setDisplayCustomBoardForm(false);
      setBoard(option);
    }
    setMlsBoardError(false);
  };

  const onMarketChange = (option) => {
    setMarket(option);
  };

  const onSubmit = handleSubmit((d) => {
    setMlsId(d.mls_id);

    if (d.mls_board_url && d.mls_board_name) {
      setBoard({
        value: 'Other',
        label: 'Other',
        mls_board_url: d.mls_board_url,
        mls_board_name: d.mls_board_name,
      });
    }

    if (!mlsBoardState) {
      setMlsStateError(true);
      return;
    }

    if (!mlsBoard) {
      setMlsBoardError(true);
      return;
    }

    if (vendorFeeToggle && mlsBoard) {
      setVisitedHere();
      setSubmitted(true);
    }
  });

  const handleUserKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    if (submitted && !showLoading) {
      setShowLoading(true);
    }
  }, [submitted, setShowLoading, showLoading]);

  useTosPaperwork(submitted);

  const onVendorCheckChange = (evt) => setVendorFeeToggle(!vendorFeeToggle);

  const errorMessages = useErrorMessages({ errors });

  if (showLoading) {
    return (
      <React.Fragment>
        <Loader>
          <h4>Sit tight! You'll be redirected to our Terms of Service</h4>
          <img src="/static/images/loading-animation.gif"/>
        </Loader>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form>
        <ClearButton onClick={previousStep}>
          <img src="/static/images/BackArrowGrey.svg" />
          <span>Back</span>
        </ClearButton>

        <h2>MLS Board Details</h2>

        <DisclaimerText>
          This information is required for us to get listings on your website
          from your MLS and/or to construct marketing campaigns.
          Please make sure this information is accurate and up to date.
        </DisclaimerText>

        <InputGroup>
          <StateLabel>Country</StateLabel>
          <Select
            name="country"
            options={[{value: 'US', label: 'United States'}, {value: 'CA', label: 'Canada'}]}
            styles={selectStyles(false)}
            onChange={onCountryChange}
            defaultValue={mlsBoardCountry} />
        </InputGroup>

        <InputGroup errorTriangleRight="10" hasError={mlsStateError}>
          <StateLabel>{mlsBoardCountry.value === 'US' ? 'State':'Province'}</StateLabel>
          <Select
            name="state"
            options={stateOptions}
            styles={selectStyles(mlsStateError)}
            onChange={onStateChange}
            value={mlsBoardState} />
        </InputGroup>

        <InputGroup errorTriangleRight="10" hasError={mlsBoardError}>
          <StateLabel>MLS Board</StateLabel>
          <Select
            name="board"
            options={boardOptions}
            styles={selectStyles(mlsBoardError)}
            onChange={onBoardChange}
            value={mlsBoard} />
        </InputGroup>

        <InputGroup hasError={errors.mls_id}>
          <StateLabel>MLS ID</StateLabel>
          <input name="mls_id" type="text" onKeyPress={handleUserKeyPress} ref={
            register({
              required: false,
            })
          }></input>
        </InputGroup>

        { shouldDisplayCustomBoardForm && (
          <React.Fragment>
            <InputGroup hasError={errors.mls_board_url}>
              <StateLabel>MLS board URL</StateLabel>
              <input name="mls_board_url" type="url" onKeyPress={handleUserKeyPress} ref={
                register({
                  required: true,
                })
              }></input>
            </InputGroup>

            <InputGroup hasError={errors.mls_board_name}>
              <StateLabel>MLS board name</StateLabel>
              <input name="mls_board_name" type="text" onKeyPress={handleUserKeyPress} ref={
                register({
                  required: true,
                })
              }></input>
            </InputGroup>
          </React.Fragment>
        )}

        { (billingPlan === "expand" || billingPlan === "conquer") && (
          <InputGroup errorTriangleRight="10">
            <StateLabel>Target Metro</StateLabel>
            <Select
              name="market"
              options={marketOptions}
              styles={selectStyles(false)}
              onChange={onMarketChange}
              value={targetMarket} />
            <TierDescription targetMarket={targetMarket} package={billingPlan} />
          </InputGroup>
        )}

        <InputGroup flex block={false} styleLabel={false} styleInput={false}>
          <CheckBox type="checkbox" onChange={onVendorCheckChange} required name="mls-vendor-fees" checked={vendorFeeToggle} ref={
            register({
              required: true,
            })
          }/>
          <span>
            By clicking this box you agree to pay MLS vendor fees issued to Real Geeks LLC. on your behalf.
          </span>
        </InputGroup>


        { Object.keys(errors).length > 0 && errorMessages }
        {
          ( mlsBoardError || mlsStateError ) ? (
            <ErrorMessageContainer key="mls-err-container">
              {mlsBoardError && <p>You must select an MLS board.</p>}
              {mlsStateError && <p>You must select a state or "Other".</p>}
            </ErrorMessageContainer>
          ) : null
        }

        <ContinueButton marginY="1em" marginTop="1em" onClick={onSubmit}>CONTINUE</ContinueButton>
      </Form>
    </React.Fragment>
  );
};

export default function BoardDetails() {
  return (
    <ObservedVisitRoute>
      <OuterWizardContainer>
        <Wizard column>
          <MLSBoardDetails />

          <StepIndicators firstStep={2} />
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};
